import './App.css';
import logo from './tsq.jpg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Logo />
        <Tagline />
        <EventsLink />
        <ComingSoon />
      </header>
    </div>
  );
}


function Logo() {
  return (
    <img src={logo} className="App-logo" alt="logo" />
  )
}

function Tagline() {
  return (
    <p>
      Real Meat and A Tasty Treat!
    </p>
  )
}

function EventsLink() {
  return (
    <a
      className="App-link"
      href="https://dillonamphitheater.com/events/events-calendar"
      target="_blank"
      rel="noopener noreferrer"
    >
      Dillon Amphitheater Schedule of Events
    </a>
  )
}

function ComingSoon() {
  return (
    <p>
      Website and store coming soon!
    </p>
  )
}

export default App;
